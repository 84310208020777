<template>
  <b-overlay :show="loading">
    <add-update-user-role ref="newUserRoleForm" @create="onNewUserRole" />
  </b-overlay>
</template>

<script>
import AddUpdateUserRole from "@/pages/administrations/user-roles/add-update-user-role.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BOverlay } from "bootstrap-vue";
import { get } from "lodash";

export default {
  name: "NewUserRole",
  components: {
    AddUpdateUserRole,
    BOverlay

  },
  data(){
    return {
      loading: false
    }
  },
  methods: {
    async onNewUserRole(payload) {
      try {
        this.loading = true;

        await this.useJwt()
            .adminService
            .createUserRole(payload)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'EditIcon',
            variant: 'success',
            text: 'User role created successfully'
          },
        });
        this.$router.go(-1);
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>

</style>
